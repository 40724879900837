<template>
<div class="action-selection__content">
    <h1>{{ $t('CheckIn.title') }}</h1>
    <p>
        {{ $t('CheckIn.message') }}
    </p>

    <QrcodeStream @detect="detected" :track="paintBoundingBox"/>
</div>
</template>


<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { partecipantService } from '@/rest';
const logo = new Image()
logo.src = require('@/assets/images/logo.png');

const logoInv = new Image()
logoInv.src = require('@/assets/images/logo-inv.png');

export default {
    name: 'QRCode',
    components: {
        QrcodeStream,
    
    },

    props: {
        checkInInfo: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        code: '',

        invalids: [],
    }),

    mounted() {
    },

    methods: {
        async detected(e) {
            
            const loading = this.$vs.loading({ type: 'border' });
            setTimeout(async() => {
                loading.close();
                this.code = (await e).content    
            }, 1000);
        },

        paintBoundingBox(detectedCodes, ctx) {
            console.log(detectedCodes)
            for (const detectedCode of detectedCodes) {
                const {
                boundingBox: { x, y, width, height }
                } = detectedCode

                ctx.lineWidth = 5

                if(this.invalids.includes(detectedCode.rawValue)){
                    ctx.strokeStyle = '#FF0000'
                } else {
                    ctx.strokeStyle = '#24B5C9'
                }
                ctx.strokeRect(x, y, width, height)

                // i want to add logo in the center of the QR code
                // logo is a png

                // fill with white color 90%
                ctx.fillStyle = 'rgba(255, 255, 255, 0.9)'
                ctx.fillRect(x, y, width, height)

                // shadow outside
                ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'
                ctx.shadowBlur = 10
                ctx.shadowOffsetX = 0
                ctx.shadowOffsetY = 0
                
                
                const imageW = width / 2;
                const imageH = height / 2;
                const imageX = x + width / 2 - imageW / 2;
                const imageY = y + height / 2 - imageH / 2;

                const l = this.invalids.includes(detectedCode.rawValue) ? logoInv : logo;

                ctx.drawImage(l, imageX, imageY, imageW, imageH);
            }
        }
    },

    watch: {
        async code(n) {
            // is uuidv4
            const isUUID = n.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);

            if(!isUUID){
                this.$vs.notification({
                    title: this.$t('Common.error'),
                    text: this.$t('CheckIn.invalid'),
                    position: 'top-center',
                    border: 'primary',
                });

                this.invalids.push(n);
                return;
            }

            const checkInResult = await partecipantService.checkIn({
                ...this.checkInInfo,
                event_secret: n
            });
            if(checkInResult.id == undefined){
                if(checkInResult.code == 'conflict'){
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('CheckIn.alreadyCheckedIn'),
                        position: 'top-center',
                        border: 'primary',
                    });
                } else if(checkInResult.code == 'resourceLimitReached'){
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('CheckIn.eventIsFull'),
                        position: 'top-center',
                        border: 'primary',
                    });
                } else {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('CheckIn.invalid'),
                        position: 'top-center',
                        border: 'primary',
                    });
                }

                

                this.invalids.push(n);
                return;
            }

            this.$emit('input', checkInResult); // partecipant id
            
        }
    }
}
</script>
<style scoped>
.action-selection__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

h1 {
    color: #000;
    font-family: "Sofia Pro";
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.0425rem;
    margin: 0;
}

p {
    color: #ABABAB;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 177.1%; /* 1.88169rem */
}
</style>