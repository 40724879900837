<template>
<div class="on-boarding">
    <PtyBack @back="goBack" :disabled="disabledBack.includes(currentStep)" :search="selectedAction == 2 && currentStep === 2" @searchToggle="searchVisible = !searchVisible" :searchPrimary="searchVisible" />
    <transition name="slide" mode="out-in">
        <ActionSelection v-if="currentStep === 1" :key="1" v-model="selectedAction" />
        <template v-if="selectedAction == 2">
            <EventSelection v-if="currentStep === 2" :key="2" v-model="selectedEvent" :searchVisible="searchVisible" />
        </template>

        <template v-if="selectedAction == 1">
            <GenderSelection v-if="currentStep === 2" :key="2" v-model="partecipant.type" />
            <Nickname v-if="currentStep === 2.5" :key="2.5" v-model="checkInInfo" />
            <QRCode v-if="currentStep === 3" :key="3" v-model="checkInResult" :checkInInfo="checkInInfo"/>

            <TableModeSelection v-if="currentStep === 4" :key="4" v-model="tableMode" />
            <TablePreference v-if="currentStep === 5" :key="5" v-model="preferred_participant_friendly_id" :myCode="partecipant.friendly_id" />
            <TableNumber v-if="currentStep === 6" :key="6" v-model="tableNumber" />

            <GenderPreferenceSelection v-if="currentStep === 7" :key="7" v-model="partecipant.looking_for_type" />

            <InteresetSelection v-if="currentStep === 8" :key="8" v-model="partecipant.ideal_match" />

            <SelfieSelection v-if="currentStep === 9" :key="9" v-model="selfieBlob" :partecipant="partecipant" />

            <TagSelection v-if="currentStep === 10" :key="10" v-model="partecipant.tag" />
        </template>

    </transition>

    <PtyNext v-if="selectedAction == 1 && currentStep > 3" :steps="7" :current="currentStep - 3" @next="goNext" />
    <PtyNext v-else :steps="0" :current="currentStep" @next="goNext" />
</div>
</template>

<script>
import {
    partecipantService
} from '@/rest';
import {
    fileService
} from '@/rest';
import axios from 'axios';
import ActionSelection from './Steps/ActionSelection.vue';
import EventSelection from './Steps/EventSelection.vue';
import QRCode from './Steps/QRCode.vue';
import GenderSelection from './Steps/GenderSelection.vue';
import TableModeSelection from './Steps/TableModeSelection.vue';
import TableNumber from './Steps/TableNumber.vue';
import TablePreference from './Steps/TablePreference.vue';
import GenderPreferenceSelection from './Steps/GenderPreferenceSelection.vue';
import InteresetSelection from './Steps/InterestSelection.vue';
import SelfieSelection from './Steps/SelfieSelection.vue';
import TagSelection from './Steps/TagSelection.vue';
import Nickname from './Steps/Nickname.vue';

import PtyBack from '../components/PtyBack.vue';
import PtyNext from '../components/PtyNext.vue';
export default {
    name: 'OnBoarding',
    components: {
        PtyBack,
        PtyNext,

        ActionSelection,
        EventSelection,
        Nickname,
        QRCode,
        GenderSelection,
        TableModeSelection,
        TableNumber,
        TablePreference,
        GenderPreferenceSelection,
        InteresetSelection,
        SelfieSelection,
        TagSelection
    },
    data: () => ({
        currentStep: 1,
        selectedAction: null,
        selectedEvent: null,

        tableMode: 'AUTO',
        tableNumber: null,

        selfieBlob: null,

        // event onboarding
        checkInResult: '',
        partecipant: {
            id: null,
            preferred_participant_friendly_id: null,
            looking_for_type: null,
            ideal_match: null,
            tag: null,
            type: null,
            picture_updated_at: null,
        },

        checkInInfo: {
            nickname: null,
            age: null,
            city: null,
        },

        searchVisible: false,

        preferred_participant_friendly_id: null,

        event: null,

        disabledBack: [
            1, // action selection
            4, // table mode selection, prevent going back to qrcode
            6, // table number, prevent going back to table mode
            7, // interest selection, prevent going back to table number
        ]
    }),

    methods: {
        goBack() {
            // if in qr code screen, go back to previous step
            if(this.currentStep == 3){
                this.currentStep = 2.5; // WORKAROUND TO AVOID STEP REMAPPING
                return;
            }

            // if current step is 2.5, go back to 2 (Nickname -> Gender)
            if(this.currentStep == 2.5){
                this.currentStep = 2; // WORKAROUND TO AVOID STEP REMAPPING
                return;
            }


            if (this.currentStep > 1) {
                // i want to skip table preference
                if (this.currentStep == 6 && this.tableMode == 'AUTO') {
                    this.currentStep = 4;
                    return;
                }
                this.currentStep--;
            } else {
                this.$router.push(('/'))
            }
        },

        async goNext() {
            var vm = this;
            if (this.selectedAction == 1) {
                if (this.currentStep == 2) {
                    if (this.partecipant.type == null) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.selectGender'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }

                    this.currentStep = 2.5; // WORKAROUND TO AVOID STEP REMAPPING
                    return;
                }

                if (this.currentStep == 2.5) { // WORKAROUND TO AVOID STEP REMAPPING

                    if (this.checkInInfo.nickname == null || this.checkInInfo.nickname.length == 0) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertNickname'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }

                    if (this.checkInInfo.age == null || isNaN(this.checkInInfo.age) || this.checkInInfo.age < 18) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertValidAge'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }

                    if (this.checkInInfo.city == null || this.checkInInfo.city.length == 0) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertCity'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }
                    this.currentStep = 3; // WORKAROUND TO AVOID STEP REMAPPING
                    return;
                }

                if (this.currentStep == 3) {
                    if (this.partecipant.id == null || this.partecipant.id.length == 0) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.scanQRCode'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }

                }

                if (this.currentStep == 5) {

                    // we have to set the preferred_participant_friendly_id, then wait for match
                    if (this.preferred_participant_friendly_id == null || this.preferred_participant_friendly_id.length < 4) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.insertFriendCode'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }

                    const loading = this.$vs.loading({
                        text: 'Ricerca amico..'
                    });
                    try {

                        const editPartecipantResponse = await partecipantService.edit({
                            preferred_participant_friendly_id: this.preferred_participant_friendly_id
                        });
                        if (!editPartecipantResponse.id) {
                            this.$vs.notification({
                                title: this.$t('Common.error'),
                                text: this.$t('OnBoarding.messages.canNotSaveChanges'),
                                position: 'top-center',
                                border: 'primary',
                            });
                            throw new Error('Impossibile salvare le modifiche');
                        }

                        let friendFound = false;
                        let startedAt = Date.now();
                        while (!friendFound && Date.now() - startedAt < 15000) {
                            friendFound = await new Promise((resolve) => {

                                partecipantService.detail(vm.preferred_participant_friendly_id).then((partecipant) => {
                                    console.log(partecipant, vm.partecipant);

                                    console.log(partecipant.preferred_participant_friendly_id, vm.partecipant.friendly_id);
                                    if (partecipant && partecipant.id) {
                                        // we have the participant
                                        if (partecipant.preferred_participant_friendly_id == vm.partecipant.friendly_id) {
                                            console.log('RESOLVE')
                                            return resolve(true);
                                        }
                                    }

                                    return resolve(false);
                                }).catch(() => {
                                    // do nothing
                                    return resolve(false);
                                });

                            });

                            console.log(friendFound);

                            // wait 2 seconds
                            await new Promise((resolve2) => {
                                setTimeout(() => {
                                    resolve2();
                                }, 2000);
                            });
                        }

                        loading.close();

                        if (!friendFound) {
                            this.$vs.notification({
                                title: this.$t('Common.error'),
                                text: this.$t('OnBoarding.messages.friendNotFound'),
                                position: 'top-center',
                                border: 'primary',
                            });

                            return;
                        }

                    } catch (e) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.friendNotFound'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        loading.close();
                        return;
                    }

                }

                if (this.currentStep == 6 && this.tableNumber == null) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.canNotAssignTable'),
                        position: 'top-center',
                        border: 'primary',
                    });
                    return;
                }

                if (this.currentStep == 7 && (this.partecipant.looking_for_type == null || this.partecipant.looking_for_type.length == 0)) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.selectGenderPreference'),
                        position: 'top-center',
                        border: 'primary',
                    });
                    return;
                }

                if (this.currentStep == 8 && (this.partecipant.ideal_match == null || this.partecipant.ideal_match.length == 0)) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.selectInterest'),
                        position: 'top-center',
                        border: 'primary',
                    });
                    return;
                }

                if (this.currentStep == 9) {
                    if (this.selfieBlob == null) {
                        this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.takeSelfie'),
                            position: 'top-center',
                            border: 'primary',
                        });
                        return;
                    }

                    const loading = this.$vs.loading({
                        text: this.$t('OnBoarding.messages.uploadingImage')
                    });

                    try {

                        // upload image
                        const uploadUrl = await fileService.getSignedUploadURL({
                            scope: 'SELFIE',
                            size: vm.selfieBlob.size,
                        });

                        if (uploadUrl == null || uploadUrl.url == null) {
                            throw new Error('Errore durante il caricamento dell\'immagine');
                        }

                        // upload to s3 using signed url
                        const response = await axios.put(uploadUrl.url, vm.selfieBlob, {
                            headers: {
                                'Content-Type': vm.selfieBlob.type
                            }
                        });

                        if (response.status != 200) {
                            throw new Error('Errore durante il caricamento dell\'immagine');
                        }

                        loading.close();

                    } catch (error) {
                        loading.close();
                        return this.$vs.notification({
                            title: this.$t('Common.error'),
                            text: this.$t('OnBoarding.messages.canNotUploadImage'),
                            border: 'primary',
                            position: 'top-center'
                        });
                    }

                    this.partecipant.picture_updated_at = new Date().toISOString();

                }

                if (this.currentStep == 10 && this.partecipant.tag == null) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.selectTag'),
                        position: 'top-center',
                        border: 'primary',
                    });
                    return;
                }

                // exception if event type is 'FREE', skip table mode selection
                if (this.currentStep == 3 && this.event && this.event.type == 'FREE') {
                    this.currentStep = 7;
                    return;
                }

                // exception: if is table mode selection is AUTO, skip table preference
                if (this.currentStep == 4 && this.tableMode == 'AUTO') {
                    this.currentStep = 6;
                    return;
                }
            }

            this.currentStep++;
        }
    },

    watch: {
        checkInInfo(n){
            console.log(n)
        },
        currentStep(n) {
            if (this.selectedAction == 2 && n == 3) {
                // go to view booking
                this.$router.push({
                    name: 'Booking',
                    params: {
                        eventId: this.selectedEvent
                    }
                })
            }

            if (this.selectedAction == 1 && n == 11) {
                // go to view waiting
                this.$router.push({
                    name: 'Waiting',
                    params: {
                        event: this.partecipant.event
                    }
                })
            }
        },

        checkInResult(n) {
            if (n) {
                if(this.partecipant.type == 'COUPLE'){
                    // skip table mode selection
                    this.currentStep = 6; // jump directly to table number
                } else {
                    this.currentStep++;
                }

                this.partecipant.friendly_id = n.friendly_id;
                this.partecipant.id = n.id;
                this.partecipant.nickname = n.nickname;
                this.partecipant.age = n.age;
                this.partecipant.city = n.city;
                this.event = n.event;
            }
        },

        partecipant: {
            deep: true,
            async handler(n) {
                if (n.id == null) {
                    return;
                }
                let copy = JSON.parse(JSON.stringify(n));
                for (let key in copy) {
                    if (copy[key] == null) {
                        delete copy[key];
                    }
                }

                const result = await partecipantService.edit(copy);
                if (result.id == null) {
                    this.$vs.notification({
                        title: this.$t('Common.error'),
                        text: this.$t('OnBoarding.messages.canNotSaveChanges'),
                        position: 'top-center',
                        border: 'primary',
                    });
                }
            }
        }
    },

    async mounted() {
        const loading = this.$vs.loading();

        try {
            const result = await partecipantService.detail();

            if (result.id != null) {
                this.partecipant = result;

                this.partecipant.__ob__.dep.notify();
                let temporaryStep = 4; // table mode selection

                if (result.preferred_participant_friendly_id != null) {
                    temporaryStep = 5; // table preference
                }

                // if table is set, skip table number
                if (result.table != null && result.table.id != null) {
                    temporaryStep = 7; // gender preference
                }

                if (result.looking_for_type != null) {
                    temporaryStep = 8; // ideal match
                }

                if (result.ideal_match != null) {
                    temporaryStep = 9; // selfie
                }

                // TODO if selfie is set, skip selfie
                if (result.picture_updated_at != null) {
                    temporaryStep = 10; // tag
                }

                if (result.tag != null) {
                    temporaryStep = 11; // waiting
                }

                if(result.event != null && result.event.type == 'TABLES'){
                    // vento format tavoli

                    if(result.event.status == 'PREMATCH_QUIZ'){
                        // go to quiz
                        this.$router.push({
                            name: 'Quiz',
                            params: {
                                event: result.event
                            }
                        })
                        loading.close();
                        return;
                    }

                    if(result.event.status == 'CALCULATE_MATCH'){
                        // go to chats
                        this.$router.push({
                            name: 'Waiting',
                            params: {
                                afterQuiz: true
                            }
                        })
                        loading.close();
                        return;
                    }
                    if(result.event.status == 'IN_PROGRESS'){
                        // go to chats
                        this.$router.push({
                            name: 'Chats',
                        })
                        loading.close();
                        return;
                    }
                }

                this.currentStep = temporaryStep;
            }
        } catch (e) {
            // it's not an error, it's just a new user

        }
        loading.close();

    }
}
</script>

<style scoped>
.on-boarding {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
    background: white;
}
</style>
